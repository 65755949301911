/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { LocaleObject, printValue } from 'yup';

const strReg = /\${\s*(\w+)\s*}/g;

export const yupTranslatePath = (
  yupLocale: LocaleObject,
  formatMessage: (descriptor: { id: string; defaultMessage: string }) => string,
): LocaleObject => {
  const newLocale: LocaleObject = { ...yupLocale };

  (Object.keys(newLocale) as Array<keyof LocaleObject>).forEach((key) => {
    const localeObject = newLocale[key];
    if (typeof newLocale === 'object' && typeof localeObject === 'object') {
      (Object.keys(localeObject) as Array<keyof typeof localeObject>).forEach(
        (action) => {
          const originalAction: any = localeObject[action];
          (localeObject[action] as any) = (
            args: Record<string, string | ReactElement>,
          ) => {
            const pathName = typeof args.path === 'string' ? args.path : 'this';
            const path = formatMessage({
              id: pathName,
              defaultMessage: pathName,
            });

            if (args.value instanceof Date || args.value instanceof dayjs) {
              // eslint-disable-next-line no-param-reassign
              args.value = dayjs(args.value as Date).format('L LT');
            }

            if (args.min instanceof Date || args.min instanceof dayjs) {
              // eslint-disable-next-line no-param-reassign
              args.min = dayjs(args.min as Date).format('L LT');
            }

            if (args.max instanceof Date || args.max instanceof dayjs) {
              // eslint-disable-next-line no-param-reassign
              args.max = dayjs(args.max as Date).format('L LT');
            }

            if (typeof originalAction === 'string') {
              return originalAction
                .replace(
                  // eslint-disable-next-line no-template-curly-in-string
                  '${path}',
                  path,
                )
                .replace(strReg, (_, g) => printValue(args[g]));
            }
            if (typeof originalAction === 'function') {
              return originalAction({ ...args, path });
            }

            return localeObject[action];
          };
        },
      );
    }
  });

  return newLocale;
};
